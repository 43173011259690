/*===========================
    01.COMMON css 
===========================*/
/*@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;500;600;700&family=Noto+Sans:wght@300;400;500;600;700&family=Source+Sans+Pro:wght@300;400;500;600;700;800&display=swap&family=Lato:wght@300;400;500;600;700;800&");*/

/*@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap&family=Source+Sans+Pro:wght@400;700&display=swap');*/

@import url("https://use.typekit.net/tre3zgw.css");

@font-face {
    font-family: HKNova;
    src: url('../fonts/HKNova-Medium.ttf');
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: "Lato", sans-serif;
    font-weight: normal;
    font-style: normal;
    letter-spacing: .005rem;
    color: #676F73;
    overflow-x: hidden;
    /*line-height: 1.6;*/
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.bottom-logo {
    width: 158px;
}

.app-store-icon {
    width: 170px;
}

a:focus,
input:focus,
textarea:focus,
button:focus,
.btn:focus,
.btn.focus,
.btn:not(:disabled):not(.disabled).active,
.btn:not(:disabled):not(.disabled):active {
    text-decoration: none;
    outline: none;
    box-shadow: none;
}

a:hover {
    color: #E8505B;
}

a {
    transition: all 0.3s ease-out 0s;
}

a,
a:focus,
a:hover {
    text-decoration: none;
}

i,
span,
a {
    display: inline-block;
}

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
    width: 100%;

}

.image-shadow {
    box-shadow: 5px 5px 20px rgba(191, 191, 191, 0.35);
}


h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Lato", sans-serif;
    font-weight: 700;
    margin: 0px;
    color: #263238;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: inherit;
}

h1 {
    font-size: 45px;
}

@media (max-width: 767px) {
    h1 {
        font-size: 36px;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    h1 {
        font-size: 40px;
    }
}

.border-min {
    border-radius: 2px;
}

.button-red {
    background: rgba(250, 80, 71, 1);
    border-color: rgba(250, 80, 71, 1);
}

.booking-button {
    font-size: 15px;
    /*border-radius: 4px;*/
    /*font-weight: 700;*/
    height: 42px;
    /*border-width: 0px;*/
    border-radius: 2px;
    opacity: 1;
    transition: opacity .3s ease;
}

.booking-button-submit {
    /*background-color: rgb(1,198,108);*/
    /* border-color: rgb(1,198,108);*/
}

.booking-button:disabled {
    /*color: rgba(255, 255, 255, 0.8);*/
    /*filter:  grayscale(20%) opacity(80%);*/
    /*background-blend-mode: saturation;*/
    /*mix-blend-mode: luminosity;*/
    /*opacity: 1;*/
    opacity: 0.5;
}

.booking-button:hover {
    /*filter: brightness(120%);*/
    opacity: 0.8;
    /*filter: contrast(130%);*/
}

.calendar-day {
    display: flex;
    flex-direction: column;
    width: 78px;
    margin: 2px 5px 5px 3px;
    border-radius: 2px;
    overflow: hidden;
    transition: all .4s ease;
    /*filter: drop-shadow(0px 0px 3px rgba(33, 35, 42, 0.0));*/
}

.calendar-day:hover {
    filter: drop-shadow(0px 2px 4px rgba(33, 35, 42, 0.13));
    /*transform: translateY(-2px);*/
}

.calendar-day-container {
    transition: all .4s ease;
    margin: 0;
    padding: 5px 0px 1px 1px;
}

.calendar-day-container:hover {
    transform: translateY(-2px);
}

.scroll-container {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;

    /*margin-bottom: 0px;*/

    /*height: 60px;*/
    /*height: auto;*/
    /*margin-bottom: 45px;*/
    /*background-color: red;*/
}

/*.scroll-container::-webkit-scrollbar {*/
/*    !*display: none;*!*/
/*    !*display: flex;*!*/
/*    width: 5px;*/
/*    height: 8px;*/
/*    background-color: #aaa; !* or add it to the track *!*/
/*}*/

.overlay{
    /*display: flex;*/
    width:100%;
    height:auto;
    pointer-events: none;
    /*z-index:2*/

    position:relative;
    /*top:0;*/
    /*left:0;*/
    /*object-fit:cover*/
}

.video-ipad-11-frame  {
    /*display: flex;*/
    /*width:100%;*/
    /*height:100%;*/
    position:absolute;
    top:9.5%;
    left:6.6%;
    right:6.4%;
    /*right:50px;*/
    /*object-fit:cover*/
}


.overlay img {
    z-index:10
}

.booking-item-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 4px;
}

.scroll-container-calendar {
    /*display: flex;*/
    /*overflow-x: auto;*/
    /*overflow-y: hidden;*/

    margin-bottom: 2px;
    /*display: flex;*/
    /*padding-bottom: 5px;*/
    /*overflow-x: scroll;*/
    /*overflow-y: hidden;*/
    /*height: auto;*/
    /*margin-bottom: 45px;*/
    /*background-color: red;*/
}
.button-container {
    transition: all .4s ease;
    padding-top: 5px;

}

.button-container:hover {
    transform: translateY(-2px);
}
.booking-select-item {
    font-size: 15px;
    /*text-overflow: ellipsis;*/
    /*overflow: hidden;*/
    white-space: nowrap;
    /*height: 100%;*/
    /*height: available;*/
    /*height: -webkit-fill-available;*/
}

.btn-dark-2 {
    background: #3b4148;
}

.booking-select-item-dark-mode-selected {
    background: rgba(250, 80, 71, 1);
    border-color: rgba(250, 80, 71, 1);
    color: #ffffff;
}

.preview-bg-image {
    background-image: url("https://reetab.s3.eu-central-1.amazonaws.com/landing-page/widget/preview-bg.jpg");
    border-radius: 3px;
    display: flex;
    justify-content: center;
    filter: drop-shadow(0px 0px 8px rgba(33, 35, 42, 0.3));
    padding: 20px;

}

.reservation-text {
    color: #1b1e21;
    font-size: 16px;
}

.booking-policy-text {
    font-size: 15px;
    line-height: 1.5em;
}

.badge-btn {
    padding: 0 6px 6px 0;
    margin: 0;
}

.book-badge {
    width: 210px;
    height: auto;
    /*margin-right: 8px;*/
    /*margin-bottom: 8px;*/
    /*transition: all 0.1s ease-out 0s;*/
    /*-webkit-filter: drop-shadow(2px 2px 8px rgba(20, 20, 20, 0));*/
    /*filter: drop-shadow(2px 2px 8px rgba(20, 20, 20, 0));*/

}

.book-badge-selected {
    -webkit-filter: drop-shadow(2px 2px 6px rgba(20, 20, 20, 0.5));
    filter: drop-shadow(3px 3px 4px rgba(20, 20, 20, 0.5));

}

.button-free {
    margin: 0;
    padding: 0;
}

.widget-item-title {
    font-weight: 600;
    /*text-transform: uppercase;*/
    font-size: 15px;
}

.terms-section {
    margin-bottom: 60px;
    padding-top: 100px;
}

.terms-section h2 {
    margin-bottom: 25px;
}

.terms-section h4 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.terms-section p {
    font-size: 17px;
    margin-bottom: 10px;
}

.section-title span {
    font-size: 25px;
    font-weight: 600;
    color: rgba(250, 80, 71, 1);
    margin-bottom: 5px;
}

h2 {
    font-size: 36px;
}

h3 {
    font-size: 30px;
}

h4 {
    font-size: 25px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 16px;
}

ul,
ol {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
}

p {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.7;
    /*line-height: 27px;*/
    margin: 0px;
}

.img-bg {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}

.error {
    color: orangered;
}

.success {
    color: #fff;
}

/*===== All Button Style =====*/
.main-btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    padding: 12px 24px;
    font-size: 17px;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    z-index: 5;
    transition: all .4s ease-in-out;
    border: none;
    background: #ff5047;
    overflow: hidden;
    box-shadow: 2px 2px 8px rgba(191, 191, 191, 0.45);

}

.navbar-btn {
    display: inline-block;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    padding: 5px 20px;
    font-size: 17px;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    z-index: 5;
    transition: all .4s ease-in-out;
    border: none;
    background: #f61f2d;
    overflow: hidden;
}

.main-btn:hover {
    color: #fff;
    box-shadow: 5px 5px 2px rgba(232, 80, 91, 0.45);
}

.btn-hover {
    position: relative;
    overflow: hidden;
}

.btn-hover::after {
    content: '';
    position: absolute;
    width: 0%;
    height: 0%;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.05);
    top: 50%;
    left: 50%;
    padding: 50%;
    z-index: -1;
    transition: all 0.3s ease-out 0s;
    transform: translate3d(-50%, -50%, 0) scale(0);
}

.btn-hover:hover::after {
    transform: translate3d(-50%, -50%, 0) scale(1.3);
}

.scroll-top {
    width: 45px;
    height: 45px;
    background: #E8505B;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: #fff;
    border-radius: 5px;
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 9;
    cursor: pointer;
    transition: all 0.3s ease-out 0s;
}

.scroll-top:hover {
    color: #fff;
    background: rgba(232, 80, 91, 0.8);
}

@keyframes animation1 {
    0% {
        transform: translateY(30px);
    }
    50% {
        transform: translateY(-30px);
    }
    100% {
        transform: translateY(30px);
    }
}

/*===== All Preloader Style =====*/
.preloader {
    /* Body Overlay */
    position: fixed;
    top: 0;
    left: 0;
    display: table;
    height: 100%;
    width: 100%;
    /* Change Background Color */
    background: #fff;
    z-index: 99999;
}

.preloader .loader {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.preloader .loader .ytp-spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 64px;
    margin-left: -32px;
    z-index: 18;
    pointer-events: none;
}

.preloader .loader .ytp-spinner .ytp-spinner-container {
    pointer-events: none;
    position: absolute;
    width: 100%;
    padding-bottom: 100%;
    top: 50%;
    left: 50%;
    margin-top: -50%;
    margin-left: -50%;
    animation: ytp-spinner-linspin 1568.2353ms linear infinite;
}

.preloader .loader .ytp-spinner .ytp-spinner-container .ytp-spinner-rotator {
    position: absolute;
    width: 100%;
    height: 100%;
    animation: ytp-spinner-easespin 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.preloader .loader .ytp-spinner .ytp-spinner-container .ytp-spinner-rotator .ytp-spinner-left {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;
    right: 50%;
}

.preloader .loader .ytp-spinner .ytp-spinner-container .ytp-spinner-rotator .ytp-spinner-right {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    left: 50%;
}

.preloader .loader .ytp-spinner-circle {
    box-sizing: border-box;
    position: absolute;
    width: 200%;
    height: 100%;
    border-style: solid;
    /* Spinner Color */
    border-color: #ff5047 #ff5047 #ddd;
    border-radius: 50%;
    border-width: 6px;
}

.preloader .loader .ytp-spinner-left .ytp-spinner-circle {
    left: 0;
    right: -100%;
    border-right-color: #ddd;
    animation: ytp-spinner-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.preloader .loader .ytp-spinner-right .ytp-spinner-circle {
    left: -100%;
    right: 0;
    border-left-color: #ddd;
    animation: ytp-right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

/* Preloader Animations */

@keyframes ytp-spinner-linspin {
    to {
        transform: rotate(360deg);
    }
}

@keyframes ytp-spinner-easespin {
    12.5% {
        transform: rotate(135deg);
    }
    25% {
        transform: rotate(270deg);
    }
    37.5% {
        transform: rotate(405deg);
    }
    50% {
        transform: rotate(540deg);
    }
    62.5% {
        transform: rotate(675deg);
    }
    75% {
        transform: rotate(810deg);
    }
    87.5% {
        transform: rotate(945deg);
    }
    to {
        transform: rotate(1080deg);
    }
}

@keyframes ytp-spinner-left-spin {
    0% {
        transform: rotate(130deg);
    }
    50% {
        transform: rotate(-5deg);
    }
    to {
        transform: rotate(130deg);
    }
}

@keyframes ytp-right-spin {
    0% {
        transform: rotate(-130deg);
    }
    50% {
        transform: rotate(5deg);
    }
    to {
        transform: rotate(-130deg);
    }
}

.mt-5 {
    margin-top: 5px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-25 {
    margin-top: 25px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-35 {
    margin-top: 35px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-45 {
    margin-top: 45px;
}

.mt-50 {
    margin-top: 50px;
}

.mt-55 {
    margin-top: 55px;
}

.mt-60 {
    margin-top: 60px;
}

.mt-65 {
    margin-top: 65px;
}

.mt-70 {
    margin-top: 70px;
}

.mt-75 {
    margin-top: 75px;
}

.mt-80 {
    margin-top: 80px;
}

.mt-85 {
    margin-top: 85px;
}

.mt-90 {
    margin-top: 90px;
}

.mt-95 {
    margin-top: 95px;
}

.mt-100 {
    margin-top: 100px;
}

.mt-105 {
    margin-top: 105px;
}

.mt-110 {
    margin-top: 110px;
}

.mt-115 {
    margin-top: 115px;
}

.mt-120 {
    margin-top: 120px;
}

.mt-125 {
    margin-top: 125px;
}

.mt-130 {
    margin-top: 130px;
}

.mt-135 {
    margin-top: 135px;
}

.mt-140 {
    margin-top: 140px;
}

.mt-145 {
    margin-top: 145px;
}

.mt-150 {
    margin-top: 150px;
}

.mt-155 {
    margin-top: 155px;
}

.mt-160 {
    margin-top: 160px;
}

.mt-165 {
    margin-top: 165px;
}

.mt-170 {
    margin-top: 170px;
}

.mt-175 {
    margin-top: 175px;
}

.mt-180 {
    margin-top: 180px;
}

.mt-185 {
    margin-top: 185px;
}

.mt-190 {
    margin-top: 190px;
}

.mt-195 {
    margin-top: 195px;
}

.mt-200 {
    margin-top: 200px;
}

.mt-205 {
    margin-top: 205px;
}

.mt-210 {
    margin-top: 210px;
}

.mt-215 {
    margin-top: 215px;
}

.mt-220 {
    margin-top: 220px;
}

.mt-225 {
    margin-top: 225px;
}

.mb-5 {
    margin-bottom: 5px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-25 {
    margin-bottom: 25px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-35 {
    margin-bottom: 35px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mb-45 {
    margin-bottom: 45px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mb-55 {
    margin-bottom: 55px;
}

.mb-60 {
    margin-bottom: 60px;
}

.mb-65 {
    margin-bottom: 65px;
}

.mb-70 {
    margin-bottom: 70px;
}

.mb-75 {
    margin-bottom: 75px;
}

.mb-80 {
    margin-bottom: 80px;
}

.mb-85 {
    margin-bottom: 85px;
}

.mb-90 {
    margin-bottom: 90px;
}

.mb-95 {
    margin-bottom: 95px;
}

.mb-100 {
    margin-bottom: 100px;
}

.mb-105 {
    margin-bottom: 105px;
}

.mb-110 {
    margin-bottom: 110px;
}

.mb-115 {
    margin-bottom: 115px;
}

.mb-120 {
    margin-bottom: 120px;
}

.mb-125 {
    margin-bottom: 125px;
}

.mb-130 {
    margin-bottom: 130px;
}

.mb-135 {
    margin-bottom: 135px;
}

.mb-140 {
    margin-bottom: 140px;
}

.mb-145 {
    margin-bottom: 145px;
}

.mb-150 {
    margin-bottom: 150px;
}

.mb-155 {
    margin-bottom: 155px;
}

.mb-160 {
    margin-bottom: 160px;
}

.mb-165 {
    margin-bottom: 165px;
}

.mb-170 {
    margin-bottom: 170px;
}

.mb-175 {
    margin-bottom: 175px;
}

.mb-180 {
    margin-bottom: 180px;
}

.mb-185 {
    margin-bottom: 185px;
}

.mb-190 {
    margin-bottom: 190px;
}

.mb-195 {
    margin-bottom: 195px;
}

.mb-200 {
    margin-bottom: 200px;
}

.mb-205 {
    margin-bottom: 205px;
}

.mb-210 {
    margin-bottom: 210px;
}

.mb-215 {
    margin-bottom: 215px;
}

.mb-220 {
    margin-bottom: 220px;
}

.mb-225 {
    margin-bottom: 225px;
}

.pt-5 {
    padding-top: 5px;
}

.pt-10 {
    padding-top: 10px;
}

.pt-15 {
    padding-top: 15px;
}

.pt-20 {
    padding-top: 20px;
}

.pt-25 {
    padding-top: 25px;
}

.pt-30 {
    padding-top: 30px;
}

.pt-35 {
    padding-top: 35px;
}

.pt-40 {
    padding-top: 40px;
}

.pt-45 {
    padding-top: 45px;
}

.pt-50 {
    padding-top: 50px;
}

.pt-55 {
    padding-top: 55px;
}

.pt-60 {
    padding-top: 60px;
}

.pt-65 {
    padding-top: 65px;
}

.pt-70 {
    padding-top: 70px;
}

.pt-75 {
    padding-top: 75px;
}

.pt-80 {
    padding-top: 80px;
}

.pt-85 {
    padding-top: 85px;
}

.pt-90 {
    padding-top: 90px;
}

.pt-95 {
    padding-top: 95px;
}

.pt-100 {
    padding-top: 100px;
}

.pt-105 {
    padding-top: 105px;
}

.pt-110 {
    padding-top: 110px;
}

.pt-115 {
    padding-top: 115px;
}

.pt-120 {
    padding-top: 120px;
}

.pt-125 {
    padding-top: 125px;
}

.pt-130 {
    padding-top: 130px;
}

.pt-135 {
    padding-top: 135px;
}

.pt-140 {
    padding-top: 140px;
}

.pt-145 {
    padding-top: 145px;
}

.pt-150 {
    padding-top: 150px;
}

.pt-155 {
    padding-top: 155px;
}

.pt-160 {
    padding-top: 160px;
}

.pt-165 {
    padding-top: 165px;
}

.pt-170 {
    padding-top: 170px;
}

.pt-175 {
    padding-top: 175px;
}

.pt-180 {
    padding-top: 180px;
}

.pt-185 {
    padding-top: 185px;
}

.pt-190 {
    padding-top: 190px;
}

.pt-195 {
    padding-top: 195px;
}

.pt-200 {
    padding-top: 200px;
}

.pt-205 {
    padding-top: 205px;
}

.pt-210 {
    padding-top: 210px;
}

.pt-215 {
    padding-top: 215px;
}

.pt-220 {
    padding-top: 220px;
}

.pt-225 {
    padding-top: 225px;
}

.pb-5 {
    padding-bottom: 5px;
}

.pb-10 {
    padding-bottom: 10px;
}

.pb-15 {
    padding-bottom: 15px;
}

.pb-20 {
    padding-bottom: 20px;
}

.pb-25 {
    padding-bottom: 25px;
}

.pb-30 {
    padding-bottom: 30px;
}

.pb-35 {
    padding-bottom: 35px;
}

.pb-40 {
    padding-bottom: 40px;
}

.pb-45 {
    padding-bottom: 45px;
}

.pb-50 {
    padding-bottom: 50px;
}

.pb-55 {
    padding-bottom: 55px;
}

.pb-60 {
    padding-bottom: 60px;
}

.pb-65 {
    padding-bottom: 65px;
}

.pb-70 {
    padding-bottom: 70px;
}

.pb-75 {
    padding-bottom: 75px;
}

.pb-80 {
    padding-bottom: 80px;
}

.pb-85 {
    padding-bottom: 85px;
}

.pb-90 {
    padding-bottom: 90px;
}

.pb-95 {
    padding-bottom: 95px;
}

.pb-100 {
    padding-bottom: 100px;
}

.pb-105 {
    padding-bottom: 105px;
}

.pb-110 {
    padding-bottom: 110px;
}

.pb-115 {
    padding-bottom: 115px;
}

.pb-120 {
    padding-bottom: 120px;
}

.pb-125 {
    padding-bottom: 125px;
}

.pb-130 {
    padding-bottom: 130px;
}

.pb-135 {
    padding-bottom: 135px;
}

.pb-140 {
    padding-bottom: 140px;
}

.pb-145 {
    padding-bottom: 145px;
}

.pb-150 {
    padding-bottom: 150px;
}

.pb-155 {
    padding-bottom: 155px;
}

.pb-160 {
    padding-bottom: 160px;
}

.pb-165 {
    padding-bottom: 165px;
}

.pb-170 {
    padding-bottom: 170px;
}

.pb-175 {
    padding-bottom: 175px;
}

.pb-180 {
    padding-bottom: 180px;
}

.pb-185 {
    padding-bottom: 185px;
}

.pb-190 {
    padding-bottom: 190px;
}

.pb-195 {
    padding-bottom: 195px;
}

.pb-200 {
    padding-bottom: 200px;
}

.pb-205 {
    padding-bottom: 205px;
}

.pb-210 {
    padding-bottom: 210px;
}

.pb-215 {
    padding-bottom: 215px;
}

.pb-220 {
    padding-bottom: 220px;
}

.pb-225 {
    padding-bottom: 225px;
}

/*===========================
    02.HEADER css 
===========================*/
/*===== NAVBAR =====*/
.navbar-area {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    transition: all 0.3s ease-out 0s;
}

.sticky {
    position: fixed;
    z-index: 99;
    background-color: #fff;
    box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease-out 0s;
}

.sticky .navbar {
    padding: 10px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .sticky .navbar {
        padding: 10px 0;
    }
}

@media (max-width: 767px) {
    .sticky .navbar {
        padding: 10px 0;
    }
}

.sticky .navbar .navbar-nav .nav-item a {
    color: #222;
}

.sticky .navbar .navbar-nav .nav-item a::before {
    opacity: .5;
}

.sticky .navbar .navbar-toggler .toggler-icon {
    background: #263238;
}

.navbar {
    border-radius: 5px;
    position: relative;
    transition: all 0.3s ease-out 0s;
    padding: 20px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .navbar {
        padding: 17px 0;
    }
}

@media (max-width: 767px) {
    .navbar {
        padding: 17px 0;
    }
}

.navbar-brand {
    padding: 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.navbar-brand img {
    /*max-width: 350px;*/
    max-height: 34px;
    width: auto;
    object-fit: contain;
    align-self: flex-start;
    /*background: #0d6efd;*/
}

.navbar-toggler {
    padding: 0;
}

.navbar-toggler:focus {
    outline: none;
    box-shadow: none;
}

.navbar-toggler .toggler-icon {
    width: 30px;
    height: 2px;
    background-color: #222;
    display: block;
    margin: 5px 0;
    position: relative;
    transition: all 0.3s ease-out 0s;
}

.navbar-toggler.active .toggler-icon:nth-of-type(1) {
    transform: rotate(45deg);
    top: 7px;
}

.navbar-toggler.active .toggler-icon:nth-of-type(2) {
    opacity: 0;
}

.navbar-toggler.active .toggler-icon:nth-of-type(3) {
    transform: rotate(135deg);
    top: -7px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .navbar-collapse {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: #fff;
        z-index: 9;
        box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
        padding: 5px 12px;
    }
}

@media (max-width: 767px) {
    .navbar-collapse {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: #fff;
        z-index: 9;
        box-shadow: 0px 15px 20px 0px rgba(38, 50, 56, 0.1);
        padding: 5px 12px;
    }
}

.navbar-nav .nav-item {
    position: relative;
    margin-left: 40px;
}

.nav-language {
    position: relative;
    margin-left: 20px;
    margin-right: -20px;
}

.language-selector {
    margin-bottom: 4px;
}

.flag {
    height: 15px;
    width: auto;
    margin-bottom: 2px;
    margin-right: 4px;
    margin-left: 4px;
    /*box-shadow: 2px 2px 8px rgba(63, 63, 63, 0.25);*/

}

.navbar-nav .nav-item-button {
    position: relative;
    margin-left: 40px;
    /*position: relative;*/
    /*background-color: #0d462c;*/
    /*overflow: hidden;*/
}


.navbar-nav .nav-item-button a {
    /*margin-top: 5px;*/
    /*margin-bottom: 5px;*/
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    transition: all 0.3s ease-out 0s;
    background: #ff5047;
    border-radius: 3px;
    padding: 6px 20px;
}

.navbar-nav .nav-item a {
    font-size: 18px;
    font-weight: 400;
    color: #5a5a5a;
    transition: all 0.3s ease-out 0s;
    padding: 10px 0;
    position: relative;
}


@media only screen and (min-width: 768px) and (max-width: 991px) {
    .navbar-nav .nav-item a {
        display: inline-block;
        padding: 6px 10px;
        color: #676F73;
    }
}

@media (max-width: 767px) {
    .navbar-nav .nav-item a {
        display: inline-block;
        padding: 6px 10px;
        color: #676F73;
    }
}

.navbar-nav .nav-item a::before {
    content: '';
    width: 48px;
    height: 48px;
    border-radius: 50%;
    /*background: rgba(232, 80, 91, 0.25);*/
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: all 0.3s ease-out 0s;
    z-index: -1;
}

.navbar-nav .nav-item a:hover {
    color: #ef3535 !important;

}

.navbar-nav .nav-item a.active {
    color: #ef3535 !important;

}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .navbar-nav .nav-item a::before {
        opacity: .5;
        top: 0;
        left: 0;
        transform: translate(0) scale(1);
        height: 100%;
        width: 0;
        border-radius: 0;
    }
}

@media (max-width: 767px) {
    .navbar-nav .nav-item a::before {
        opacity: .5;
        top: 0;
        left: 0;
        transform: translate(0) scale(1);
        height: 100%;
        width: 0;
        border-radius: 0;
    }
}

.navbar-nav .nav-item a:hover::before, .navbar-nav .nav-item a.active::before {
    transform: translate(-50%, -50%) scale(1);

}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .navbar-nav .nav-item a:hover::before, .navbar-nav .nav-item a.active::before {
        opacity: .5;
        top: 0;
        left: 0;
        transform: translate(0) scale(1);
        width: 100%;
    }
}

@media (max-width: 767px) {
    .navbar-nav .nav-item a:hover::before, .navbar-nav .nav-item a.active::before {
        opacity: .5;
        top: 0;
        left: 0;
        transform: translate(0) scale(1);
        width: 100%;
    }
}

.navbar-nav .nav-item:hover .sub-menu {
    top: 100%;
    opacity: 1;
    visibility: visible;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .navbar-nav .nav-item:hover .sub-menu {
        top: 0;
    }
}

@media (max-width: 767px) {
    .navbar-nav .nav-item:hover .sub-menu {
        top: 0;
    }
}

.navbar-nav .nav-item .sub-menu {
    width: 200px;
    background-color: #fff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 110%;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-out 0s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .navbar-nav .nav-item .sub-menu {
        position: relative;
        width: 100%;
        top: 0;
        display: none;
        opacity: 1;
        visibility: visible;
    }
}

@media (max-width: 767px) {
    .navbar-nav .nav-item .sub-menu {
        position: relative;
        width: 100%;
        top: 0;
        display: none;
        opacity: 1;
        visibility: visible;
    }
}

.navbar-nav .nav-item .sub-menu li {
    display: block;
}

.navbar-nav .nav-item .sub-menu li a {
    display: block;
    padding: 8px 20px;
    color: #222;
}

.navbar-nav .nav-item .sub-menu li a.active, .navbar-nav .nav-item .sub-menu li a:hover {
    padding-left: 25px;
    color: #E8505B;
}

.navbar-nav .sub-nav-toggler {
    display: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .navbar-nav .sub-nav-toggler {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        background: none;
        color: #222;
        font-size: 18px;
        border: 0;
        width: 30px;
        height: 30px;
    }
}

@media (max-width: 767px) {
    .navbar-nav .sub-nav-toggler {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        background: none;
        color: #222;
        font-size: 18px;
        border: 0;
        width: 30px;
        height: 30px;
    }
}

.navbar-nav .sub-nav-toggler span {
    width: 8px;
    height: 8px;
    border-left: 1px solid #222;
    border-bottom: 1px solid #222;
    transform: rotate(-45deg);
    position: relative;
    top: -5px;
}

.hero-section {
    position: relative;
    padding-top: 140px;
    margin-bottom: 50px;
    z-index: 1;
}

.hero-section .hero-shape {
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: -100px;
    display: none;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .hero-section {
        padding-top: 150px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .hero-section {
        padding-top: 160px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero-section {
        padding-top: 160px;
    }
}

@media (max-width: 767px) {
    .hero-section {
        padding-top: 80px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero-section .hero-content {
        margin-bottom: 0px;
    }
}

@media (max-width: 767px) {
    .hero-section .hero-content {
        margin-bottom: 0px;
    }
}

.hero-section .hero-content h1 {
    font-size: 55px;
    margin-bottom: 35px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .hero-section .hero-content h1 {
        font-size: 55px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .hero-section .hero-content h1 {
        font-size: 48px;
    }
}

@media (max-width: 767px) {
    .hero-section .hero-content h1 {
        font-size: 40px;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .hero-section .hero-content h1 {
        font-size: 50px;
    }
}

.hero-section .hero-content h1 span {
    font-weight: 400;
    display: inline;
}

.hero-section .hero-content p {
    margin-bottom: 35px;
    padding-right: 55px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .hero-section .hero-content p {
        padding-right: 0;
    }
}

@media (max-width: 767px) {
    .hero-section .hero-content p {
        padding-right: 0;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .hero-section .hero-img img {
        width: 100%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .hero-section .hero-img img {
        width: 100%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero-section .hero-img img {
        width: 100%;
    }
}

@media (max-width: 767px) {
    .hero-section .hero-img img {
        width: 100%;
    }
}

.service-section .single-service {
    margin-bottom: 30px;
    padding: 30px;
    border-radius: 5px;
    transition: all 0.3s ease-out 0s;
}

.service-section .single-service:hover {
    box-shadow: 0 10px 35px rgba(0, 0, 0, 0.05);
}

.service-section .single-service .icon {
    margin-bottom: 40px;
    width: 90px;
    height: 90px;
}

.service-section .single-service .icon img {
    max-width: 100%;
}

.service-section .single-service .icon-big {
    margin-bottom: 30px;
    width: 220px;
    height: 220px;
}

.service-section .single-service .icon-big img {
    max-width: 100%;
}


.service-section .single-service .content h3 {
    font-weight: 600;
    margin-bottom: 25px;
}

.about-section .about-img img {
    border-radius: 8px;
    width: 100%;
}

.about-section .about-content {
    padding-left: 75px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-section .about-content {
        padding-left: 0;
        margin-top: 50px;
    }
}

@media (max-width: 767px) {
    .about-section .about-content {
        padding-left: 0;
        margin-top: 50px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-section .about-content {
        padding-left: 25px;
        /*padding-left: 0;*/
        /*margin-top: 50px;*/
    }
}

.about-section .about-content .section-title h1 {
    margin-bottom: 35px;
}

.about-section .about-content .section-title p {
    margin-bottom: 35px;
}

.about-section .about-content .rating-meta {
    margin-bottom: 35px;
}

.about-section .about-content .rating-meta h5 {
    font-size: 20px;
    font-weight: 700;
    margin-right: 20px;
}

.about-section .about-content .rating-meta .rating {
    color: #E96A20;
}

.about-section .about-content .counter-up {
    background: #E8505B;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}

.about-section .about-content .counter-up .single-counter {
    padding: 20px 0;
    margin: 0 15px;
}

.about-section .about-content .counter-up .single-counter::before, .about-section .about-content .counter-up .single-counter::after {
    content: '';
    position: absolute;
    width: 2px;
    height: calc(100% - 40px);
    background: #fff;
    top: 20px;
    border-radius: 5px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-section .about-content .counter-up .single-counter::before, .about-section .about-content .counter-up .single-counter::after {
        opacity: 0;
    }
}

@media (max-width: 767px) {
    .about-section .about-content .counter-up .single-counter::before, .about-section .about-content .counter-up .single-counter::after {
        opacity: 0;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .about-section .about-content .counter-up .single-counter::before, .about-section .about-content .counter-up .single-counter::after {
        opacity: 1;
    }
}

.about-section .about-content .counter-up .single-counter::before {
    left: -50%;
}

.about-section .about-content .counter-up .single-counter::after {
    right: -50%;
}

.about-section .about-content .counter-up .single-counter h3 {
    color: #fff;
    margin-bottom: 5px;
}

.about-section .about-content .counter-up .single-counter h5 {
    font-weight: 600;
    color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .delivery-img {
        margin-bottom: 50px;
    }
}

@media (max-width: 767px) {
    .delivery-img {
        margin-bottom: 50px;
    }
}

.delivery-img img {
    width: 100%;
}

.testimonial-section .testimonial-wrapper {
    margin-top: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .testimonial-section .testimonial-wrapper {
        margin-top: 0;
    }
}

@media (max-width: 767px) {
    .testimonial-section .testimonial-wrapper {
        margin-top: 0;
    }
}

.testimonial-section .testimonial-wrapper .-mt-30 {
    margin-top: -30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .testimonial-section .testimonial-wrapper .-mt-30 {
        margin-top: 0px;
    }
}

@media (max-width: 767px) {
    .testimonial-section .testimonial-wrapper .-mt-30 {
        margin-top: 30px;
    }
}

.testimonial-section .testimonial-wrapper .-mt-60 {
    margin-top: -60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .testimonial-section .testimonial-wrapper .-mt-60 {
        margin-top: 0px;
    }
}

@media (max-width: 767px) {
    .testimonial-section .testimonial-wrapper .-mt-60 {
        margin-top: 0px;
    }
}

.testimonial-section .single-testimonial {
    padding: 25px 30px 25px 30px;
    border: 1px solid transparent;
    /*border: 1px solid #e0e0e0;*/

    border-radius: 6px;
    /*margin-bottom: 30px;*/
    transition: all 0.3s ease-out 0s;
    /*justify-content: center;*/
    position: relative;
    /*transition: 2.5s;*/
    top: 0;


    height: 100%;
}

.testimonial-section .single-testimonial:hover {
    box-shadow: -10px -10px 40px rgba(149, 149, 149, 0.25);
    border-color: transparent;
    top: -12px;
}

.testimonial-section .single-testimonial .rating {
    margin-bottom: 30px;
    color: #E96A20;
}

.testimonial-section .single-testimonial .rating i {
    margin-right: 10px;
    font-size: 14px;
}

.testimonial-section .single-testimonial .content {
    margin-bottom: 30px;
}

.testimonial-section .single-testimonial .info {
    /*display: flex;*/
    align-items: center;
    justify-content: center;
}

.testimonial-section .single-testimonial .info .image {
    max-width: 48px;
    width: 100%;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 20px;
}

.testimonial-section .single-testimonial .info .image img {
    width: 100%;
}

.testimonial-section .single-testimonial .info .text h5 {
    font-weight: 600;
}

.testimonial-section .single-testimonial .info .text p {
    font-size: 16px;
}

.partner-section .single-partner {
    opacity: .43;
    transition: all 0.3s ease-out 0s;
}

.partner-section .single-partner:hover {
    opacity: 1;
}

.footer {
    background: #1d262b;
    position: relative;
    z-index: 1;
}

.footer .footer-shape .shape {
    position: absolute;
    z-index: -1;
    display: none;
}

.footer .footer-shape .shape.shape-1 {
    left: 50px;
    bottom: 0;
}

.footer .footer-shape .shape.shape-2 {
    right: 0;
    bottom: 0;
}

.footer .footer-widget {
    margin-bottom: 30px;
}

.footer .footer-widget .logo a {
    display: inline-block;
    margin-bottom: 25px;
}

.footer .footer-widget .download-btns {
    margin-top: 9px;
}

.footer .footer-widget .download-btns a {
    display: inline-flex;
    align-items: center;
    /*padding: 14px 30px;*/
    /*border-radius: 10px;*/
    /*background: rgba(255, 255, 255, 0.08);*/
    /*border: 1px solid #fff;*/
    margin-bottom: 12px;
    margin-right: 12px;
}

.footer .footer-widget .download-btns a:hover {
    /*background: #E8505B;*/
    /*border-color: #E8505B;*/
}

.footer .footer-widget .download-btns a .icon {
    font-size: 24px;
    margin-right: 20px;
    color: #fff;
}

.footer .footer-widget .download-btns a .text {
    font-size: 10px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.7);
}

.footer .footer-widget .download-btns a .text b {
    display: block;
    font-weight: 600;
    font-size: 16px;
    color: #fff;
}

.footer .footer-widget h3 {
    color: #fff;
    margin-bottom: 25px;
}

.footer .footer-widget ul.links li a {
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    color: #fff;
}

.footer .footer-widget ul.links li a:hover {
    color: #E8505B;
}

@media (min-width: 1600px) {
    .footer .footer-shape .shape {
        display: block;
    }

    .hero-section .hero-shape {
        display: block;
    }
}

.pricing-ul {
    padding-left: 5px;
    padding-right: 5px;
}

.pricing-ul-no-padding {
    padding-left: 0px;
    margin-left: 5px;
    padding-right: 0px;
}


.pricing-ul li {
    padding-top: 4px;
    padding-bottom: 4px;
    /*line-height: 40px;*/
    display: flex;
    /*align-items: center;*/
    font-size: 19px;
    align-items: flex-start;

    /*position: relative;*/
}

.extra-text {
    font-size: 15px;
    color: #767676;
}

.pricing-ul li {
    color: #373737;
}


.pricing-ul li i {
    padding-right: 12px;
    /*font-size: 1.5rem;*/
    /*font-size: 1.6rem;*/
    font-size: 1.6rem;
    /*color: #ef3535;*/
    color: #ff5047;
    /*color: #41d691;*/
}

.pricing-ul li p {
    margin-top: 2px;
    /*font-size: 1.6rem;*/
}

.pricing-ul .not-included {
    color: #d9d9d9 !important;
}

.pricing-ul .not-included i {
    color: #d9d9d9 !important;
}

.pricing-ul .extra {
    font-size: 17px;
    padding-top: 6px;
    padding-bottom: 6px;
    align-items: flex-start;

    /*color: #d9d9d9 !important;*/
}

.pricing-ul .extra li {
    line-height: 10px;
    vertical-align: top;
}

.pricing-ul .extra i {
    font-size: 0.4rem;
    padding-left: 8px;
    padding-right: 20px;
    padding-top: 14px;
    /*vertical-align: top;*/
    color: #474747 !important;
}

.pricing-ul .extra .tooltip-x {
    font-size: 1.2rem;
    padding-left: 10px;
    padding-right: 10px;
    color: #ef3535 !important;
}

.blue-text {
    color: royalblue;
}


.month {
    color: #c8c8c8;
    padding-top: 10px;
    font-size: 22px;
}

.plan-name {
    color: rgba(250, 80, 71, 1);
    font-size: 24px;
    /*letter-spacing: .1rem;*/
    font-weight: 700;
}

.plan-button {
    position: absolute;
    bottom: 0;
}

.pro-plan-button {
    /*display: inline-block;*/
    width: 100%;
    /*font-weight: 600;*/
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    padding: 17px 37px;
    font-size: 18px;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    z-index: 5;
    transition: all .4s ease-in-out;
    border: none;
    background: #ff5047;
    overflow: hidden;
    box-shadow: 2px 2px 8px rgba(212, 212, 212, 0.4);

}

.pro-plan-button:hover {
    color: #fff;
    transition: 0.1s ease-in;
    background-color: #ef4942
    /*box-shadow: 4px 4px 12px rgb(160, 160, 160);*/
    /*box-shadow: 5px 5px 2px rgba(232, 80, 91, 0.45);*/
}

.aaa {
    /*display: flex;*/
    align-items: center;
}

.sign-up-button {
}

.sign-up-button:hover {
    color: #fff;
    background: #0d6efd;
    box-shadow: 3px 3px 2px rgba(232, 80, 91, 0.45);
}


label {
    color: rgb(96, 102, 105);
    font-weight: 600;
    font-size: 13px;
    /*text-transform: uppercase;*/
    /*letter-spacing: 1px;*/

}

.send-button {
    background: #ff5047;
    color: white;
    padding: 7px 18px;
    border-radius: 4px;
    border-width: 0;
    font-weight: 500;
}

.send-button:hover {
    color: #fff;
    background: #ff5047;
    box-shadow: 3px 3px 10px rgba(161, 161, 161, 0.45);
}

.send-button:disabled {
    color: #fff;
    background: rgba(255, 80, 71, 0.45);
}


.input-x {
    border-radius: 3px;
    color: #393939;
}

.input-x::placeholder {
    color: rgba(103, 111, 115, 0.50);
    /*opacity: 0.6;*/
}

.input-x-dark {
    border-radius: 3px;
    color: #ffffff;
    background: #343a40;
    border-color: #343a40;
    /*border-width: 0;*/
}

.input-x-dark::placeholder {
    color: rgba(174, 186, 192, 0.5);
    /*opacity: 1;*/
}

.input-x-dark:focus {
    background: #505860;
    color: #ffffff;

    /*color: rgba(174, 186, 192, 0.5);*/
    /*opacity: 1;*/
}


.free-plan-button {
    /*display: inline-block;*/
    width: 100%;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    padding: 17px 37px;
    font-size: 18px;
    border-radius: 3px;
    color: #ff5047;
    border: 1px solid #ff5047;
    cursor: pointer;
    z-index: 5;
    transition: all .2s ease-in-out;
    background: #fff;
    overflow: hidden;
}

.free-plan-button:hover {
    /*color: #fff;*/
    /*background: #ef4942*/
    box-shadow: 4px 4px 2px rgba(255, 80, 71, 0.25);
}

.strike-diagonal-price {
    position: relative;
}

.strike-diagonal-price:before {
    position: absolute;
    content: "";
    left: -35px;
    top: 45%;
    right: -35px;
    border-top: 2px solid;
    border-color: black;
    -webkit-transform: rotate(-15deg);
    -moz-transform: rotate(-15deg);
    -ms-transform: rotate(-15deg);
    -o-transform: rotate(-15deg);
    transform: rotate(-15deg);
}

.tooltip-width {
    width: 350px;
    max-width: 60%;
}
